
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.logo {
  /* background-image: url(assets/images/LOGO.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 145px;
  height: 60px;
}

.GoogleIcon
{
    background-image: url(assets/images/icon-google.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 22px;
    height: 22px;
}

.newsletterBlock
{
  background-image: url(assets/images/newsletter.jpg);
  background-size: cover;
  background-position: center center;
}

.icon-quotes
    {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(assets/images/icon-quotes.svg);
        background-size: contain;
    }

.shadow-element::after
    {
        content: "";
        position: absolute;
        display: inline-block;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(assets/images/corner-element.svg);
        background-position: center bottom;
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
        margin-left: 50px;
        margin-top: -1px;

    }

.IdCardIcon
{
    background-image: url(assets/images/icon-id-card.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 22px;
    height: 28px;
}


.test1
{
  background-color: red;
}

.test2
{ 
  background-color: green;
}

.test3
{
  background-color: yellow;
}

.test4
{
  background-color: blue;
}

.number.courses::before, .number.students::before
{
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.number.courses::before
{
  background-image: url(assets/images/icon-courses.svg);
}

.number.students::before
{
  background-image: url(assets/images/icon-peoples.svg);
}

.sugestionItem
{

}

.categoryTopBar .name
{
  background-image: url(assets/images/triangle.svg);
  background-position: center right;
  background-size: cover;
}

.courseLIST .banner
{
  background-image: url(assets/images/course-list-bg.svg);
  background-size: cover;
  background-position: center center;
}

.menuGeneral .icon-plus::after
{
  background-image: url(assets/images/icon-plus.svg);
}

.menuGeneral .icon-minus::after
{
  background-image: url(assets/images/icon-plus.svg);
}

.menuGeneral .section1 .icon-plus::after
{
  background-image: url(assets/images/icon-plus-right.svg) !important;
}

.menuGeneral .section1 .icon-minus::after
{
  background-image: url(assets/images/icon-plus-right.svg) !important;
}


.detailsGeneral li
{
  list-style: url(assets/images/icon-check.svg) !important;
  margin: 10px 0;
}

.detailsGeneralround li
{
  list-style: url(assets/images/icon-dot.svg) !important;
  margin: 10px 0;
}

.fa-telegram::before
{
  background-image: url(assets/images/telegram-app.svg) !important;
}

.fa-facebook-f::before
{
  background-image: url(assets/images/facebook.svg) !important;
}

.fa-twitter::before
{
  background-image: url(assets/images/twitter.svg) !important;
}

.fa-linkedin-in::before
{
  background-image: url(assets/images/linkedin.svg) !important;
}

.fa-whatsapp::before
{
  background-image: url(assets/images/whatsapp.svg) !important;
}

.fa-envelope::before
{
  background-image: url(assets/images/envelope.svg) !important;
}

.fa-google-plus-g::before
{
  background-image: url(assets/images/gp.svg) !important;
}

.fa-pinterest::before
{
  background-image: url(assets/images/pinterest.svg) !important;
}

.fa-reddit::before
{
  background-image: url(assets/images/reddit.svg) !important;
}

.fa-tumblr::before
{
  background-image: url(assets/images/tumblr.svg) !important;
}

.fa-vk::before
{
  background-image: url(assets/images/vk.svg) !important;
}

.fa-xing::before
{
  background-image: url(assets/images/xing.svg) !important;
}

.fa-buffer::before
{
  background-image: url(assets/images/buffer.svg) !important;
}

.fa-hacker-news::before
{
  background-image: url(assets/images/hacker.svg) !important;
}

.fa-flipboard::before
{
  background-image: url(assets/images/flipboard.svg) !important;
}

.fa-instapaper::before
{
  background-image: url(assets/images/instapaper.svg) !important;
}

.fa-livejournal::before
{
  background-image: url(assets/images/livejournal.svg) !important;
}

.fa-mailru::before
{
  background-image: url(assets/images/mailru.svg) !important;
}

.fa-meneame::before
{
  background-image: url(assets/images/meneame.svg) !important;
}

.fa-odnoklassniki::before
{
  background-image: url(assets/images/odnoklassniki.svg) !important;
}

.fa-print::before
{
  background-image: url(assets/images/print.svg) !important;
}

.fa-qzone::before
{
  background-image: url(assets/images/qzone.svg) !important;
}

.fa-renren::before
{
  background-image: url(assets/images/renren.svg) !important;
}

.fa-skype::before
{
  background-image: url(assets/images/skype.svg) !important;
}

.fa-sms::before
{
  background-image: url(assets/images/sms.svg) !important;
}

.fa-stumbleupon::before
{
  background-image: url(assets/images/stumbleupon.svg) !important;
}

.fa-viber::before
{
  background-image: url(assets/images/viber.svg) !important;
}

.fa-weibo::before
{
  background-image: url(assets/images/weibo.svg) !important;
}

.becomeSteps .MuiStepConnector-line.MuiStepConnector-lineHorizontal
{
    background-image: url(assets/images/stepper-line.svg) !important;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.stepp
{
  width: calc(30px + (60 - 30)*((100vw - 320px)/(2560 - 320)));
  height: calc(30px + (60 - 30)*((100vw - 320px)/(2560 - 320)));
  background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.step1
{
  background-image: url(assets/images/step1.svg) !important;
}

.step2
{
  background-image: url(assets/images/step2.svg) !important;
}

.step3
{
  background-image: url(assets/images/step3.svg) !important;
}

.step4
{
  background-image: url(assets/images/step4.svg) !important;
}

.sectionThree
{
  background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(assets/images/cifre.png) !important;
}

.icon-start-teach
{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(assets/images/icon-start-teach.svg) !important;
  width: calc(30px + (70 - 30)*((100vw - 320px)/(2560 - 320)));
  height: calc(40px + (80 - 40)*((100vw - 320px)/(2560 - 320)));
}

.teacher-logo
{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* background-image: url(assets/images/teacher-logo.svg) !important; */
  display: inline-block;
  width: 25px;
  height: 25px;

}


.boxUploader
{
  width: 100%;
  display: flex;
  border: dashed 2px #D2D2D2;
  align-items: center;
  justify-content: center;
}

.boxUploader .drag
{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
  text-align: center;
  color: #051324;
}

.boxUploader .drag .alto
{
  color: #d2d2d2;
}

.boxUploader .drag .btnUp
{
  background: #E8FAFF;
  border-radius: 20px;
  font-family: 16px !important;
  padding: 10px 25px;
  display: inline-block;
  font-weight: 500;
}

.boxUploader .drag .icon
{
  color: #D2D2D2;
  width: 50px;
  height: 50px;
}





