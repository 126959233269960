$white: #fff;
$boulder:#777;
$primary:#0072EF;
$danger:#FF3434;
$success:#01E65C;
$black-pearl:#051324;
$background:#FAFEFF;
$lilywhite:#E8FAFF;
$scieneblue:#006ADE;
$gold:#FFD102;
$malachite: #01E65C;
$lilygray: #e1e1e1;

body
{
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
    background: $background;
    color: $black-pearl;

    hr.MuiDivider-root
    {
        background-color: $lilywhite !important;
        border-color:  $lilywhite !important;
    }



    @media only screen and (max-width: 768px)
    {
        font-size: 14px;
    }

    .active
    {
        color: $danger;
    }

    .disabled
    {
        color: $boulder;
    }
}

.widthSocialEdit
{
    max-width: 50%;
    @media only screen and (max-width: 768px)
    {
        width: 100%;
    }
}

.container
{
    max-width: 1536px;
    padding: 0 15px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin:auto;
    @media only screen and (max-width: 768px)
    {
        padding: 0 15px;
    }
}

header
{
    background: $white;
    box-shadow: 0px 5px 30px rgba(5, 19, 36, 0.05);
    padding-top: 15px;
    padding-bottom: 15px;

    .typeInterfaceBtn
    {
        color: $black-pearl;
        font-size: 15px;
        text-transform: none;
    }

    .el1
    {
        display: none;
    }

    .el5 
    {
        margin-left: 0px;
        display: flex;
        align-items: center;
    }

    .el3
    {
        justify-content: center;
        display: flex;
    }

    .el4
    {
        margin: auto;
    }

    @media only screen and (max-width: 1300px)
    {
        .el1
        {
            display: flex;
        }

        .el2
        {
            display:none;
        }

        .el3
        {
            display: inline-flex;
            justify-content: center;
        }

        .el4
        {
            display: none;
        }

        .el5
        {
            .el5_1
            {
                display: none;
            }

            .el5_4
            {
                display: none;
            }
        }
    }
  
    .headerStyle
    {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        

        .btnTeachTop
        {
            color: $primary;
            font-size: 14px;
            background-color: $background;
            border-radius: 50px;
            padding: 10px 15px;
            display: flex !important;
            flex-direction: row !important;
            align-items: center;
            text-decoration: none;
            line-height: 1.2;
            font-weight: 600;
            transition: all ease 0.5s;
            &:hover
            {
                background: $lilywhite;
            }
        }
    }

    .btnTop
    {
        text-transform: uppercase;
        padding: 10px 15px;
        box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
        border-radius: 5px;
        text-decoration: none;
        font-weight: 500;
        transition: all ease 0.5s;
        &.btnSignInTop
        {
            background: $primary;
            color: $white;
            border: solid 2px $primary;
        }

        &.btnSignUpTop
        {
            color: $primary;
            border: solid 2px $primary;
        }
        &:hover
        {
            box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 1);
            opacity: 0.75;
        }
    }

}

.footer
{
    background: #fff;
    color: #777;
    .logo
    {
        margin-right: 30px;
    }

    .row
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }

    .firstBlock
    {
        box-shadow: 0px 5px 5px -5px rgba(2, 11, 23, 0.05);
        box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.02);
        padding: 45px 15px;
        box-sizing: border-box;
        font-weight: 500;
        color:$black-pearl;

        .languageBottom
        {
            border:  solid 1px rgba(210, 210, 201, 0.1);
            font-size: 16px;
            font-weight: 500;
            color:$black-pearl;
            text-transform: none !important;
            float: right;
            @media only screen and (max-width: 768px)
            {
                float: left;
            }
        }

        .bottomMenu
        {
            a
            {
                text-decoration: none;
                color:$black-pearl;
                transition: all ease 0.5px;
                margin-bottom: 7px;
                text-decoration: none;
                :hover
                {
                    color: #0072EF;
                }
            }
        }
    }

    .secondBlock
    {
        box-shadow: inset 0px 1px 0px $background;
        box-sizing: border-box;
        padding: 15px;

        .infomenu
        {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            @media only screen and (max-width: 768px)
            {
                flex-direction: column;
                margin-left: 0px;
            }
            a
            {
                margin-left: 15px;
                color: $boulder;
                text-decoration: none;
                transition: all ease 0.5s;
                @media only screen and (max-width: 768px)
                {
                    margin-left: 0px;
                }
                &:hover
                {
                    color: $black-pearl;
                    text-decoration: underline;
                }
            }
        }
    }
}

.form-select
{
    background: $white;
    border-radius: 5px;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
    padding: 12px 15px;
    &.MuiInputBase-root.MuiInput-root
    {
        border-radius: 5px;
        position: relative;
        margin: 0px;
        &::before
        {
            border-bottom: 2px solid $boulder !important;
            border-radius: 5px;
        }

        &::after
        {
            border-bottom: 2px solid $primary !important;
            border-radius: 5px !important;
            height: 10px;
        }
    }
}

.googleButton {
    border: 1px solid $white !important;
    border-radius: 5px !important;
    padding: 3px 0px 4px 0px !important;
    background: $white !important;
    min-width: 136px !important;
    &:hover {
      border: 1px solid $primary !important;
    }
  
    * {
      border: none !important;
  
      &:hover {
        border: none !important;
      }
    }
  }

  .dialogPages{
    width: calc(100% - 40px) !important; 
    // min-height: calc(100vh - #{$header-height}px - #{$footer-height}px ) !important;
      min-height: calc(100vh ) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    // padding-bottom: 32px !important;
    padding: 0px 20px 32px 20px !important;
  
    .errorCodeText{
      font-size: 150px !important;
      font-weight: 600 !important;
    }
  
    .errorText{
      margin-top: 70px !important;
      font-size: 36px !important;
      font-family: 'Open Sans', sans-serif !important;
      font-weight: 500 !important;
      text-align: center !important;
    }

    .errorTextSmall{
        margin-top: 10px !important;
        font-size: 20px !important;
        font-family: 'Open Sans', sans-serif !important;
        font-weight: 400 !important;
        text-align: center !important;
    }
}


.loadingPage{
    width: 100% !important;
    height: 100vh !important;
    // display: flex !important;
    // justify-content: center !important;
    // align-items: center !important;
}


.loadingPageTextBox{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    height: 99vh !important;
    width: 100% !important;
    background: $white !important;
    gap: 10px !important;
}

.loadingPageText{
    font-size: 30px !important;
    font-weight: 600 !important;
  }

  .loadingPageTextPercent{
    font-size: 18px !important;
    font-weight: 500 !important;
    text-align: center !important;
  }
  
.form-autocomplete
{
    background: $white;
    border-radius: 5px;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 5px 0px;
    border-bottom: 2px solid $boulder !important;
    border-radius: 5px;
    input
    {
        border: 0px !important;
    }

    fieldset
    {
        border: 0px !important;
        box-shadow: none;
    }
}


.form-picker
{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
    background: $white;
    border-bottom: 2px solid $boulder !important;
    border-radius: 5px;
    fieldset
    {
        border: 0px !important;
    }
    label
    {
        display: none !important;
    }
    .MuiInputBase-root
        {
            border-radius: 5px;
            position: relative;
            &::before
            {
                
            }

            &::after
            {
                border-bottom: 2px solid $primary !important;
                border-radius: 5px !important;
                height: 10px;
            }
        }
    
}

.form-input 
    {
        background: $white;
        border-radius: 5px;

        .MuiInputBase-root
        {
            margin: 0px;
        }
        
        .MuiInputBase-root.MuiInput-root
        {
            border-radius: 5px;
            position: relative;
            &::before
            {
                border-bottom: 2px solid $boulder !important;
                border-radius: 5px;
            }

            &::after
            {
                border-bottom: 2px solid $primary !important;
                border-radius: 5px !important;
                height: 10px;
            }
        }

        input
        {
            border-radius: 5px;
            box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
            padding:15px 15px;
        }

        label
        {
            padding: 15px;
            margin-top: -20px;
        }

        .MuiInputLabel-root.Mui-focused, .MuiFormLabel-filled
        {
            display: none;
        }
        
    }

.form-input-box
{

    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1) !important;
    padding: 0 10px;
    border-bottom: 2px solid $boulder !important;
    border-radius: 5px !important;
    background: $white !important;
    color: $boulder !important;
    box-shadow: 0px 2px 0px #777777, 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
    input
    {
        min-width: 300px;
        height: 20px;
    }
    label.Mui-focused
    {
        display: none !important;
    }
    fieldset
    {
        border: 0px !important;
        box-shadow: none !important;
    }
}

.form-input.autocomplete
{
    input
    {
        padding: 15px !important;
    }
}

.form-danger
{
    .MuiInputBase-root.MuiInput-root
    {
        &::after
        {
            border-bottom: 2px solid $danger !important;
            border-radius: 5px !important;
            height: 10px;
        }
    }
}

.form-success
{
    .MuiInputBase-root.MuiInput-root
    {
        &::after
        {
            border-bottom: 2px solid $success !important;
            border-radius: 5px !important;
            height: 10px;
        }
    }
}

.box-form-input-icon
{
    border-radius: 5px;
    z-index: 0;
    position: relative;

    .MuiSvgIcon-root
    {
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: 10;
        fill: $boulder !important;
    }
    
    .form-input
    {
        input
        {
            border-radius: 5px;
            box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
            padding-left: 40px !important;
        }
    
        label
        {
            padding-left: 40px;
        }
    }
    
}

.btn
{
    font-size: 16px !important;
    padding:11px 10px !important;
    box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
    
   
}

.pxBnt
{
    padding: 9px 25px !important;
}




.btn-auth-social
{
    color: $black-pearl !important;
    font-size: 15px !important;
    border: solid 1px $background !important;
    text-transform: none !important;
    background: $white !important;
    padding: 10px 10px !important;
    border-radius: 5px !important;
    min-width: 136px !important;


    .fa{
        margin-right: 10px !important;
    }

    

    
    &:hover
    {
        box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15) !important;
        border: solid 1px $primary !important;
        background: $white !important;
        border-radius: 5px !important;
   
    }
}

.FacebookIcon
{
    fill: #1976D2 !important;
    font-size: 25px !important;
}

.AppleIcon
{
    font-size: 25px !important;
}

.text-strike{
    overflow: hidden;
    text-align: center;
    color: $black-pearl;
    font-size: 16px;
    &:before,
    &:after {
    //   background-color: $lilywhite;
    background-color: $lilygray !important;
      content: "";
      display: inline-block;
      height: 2px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
        right: 0.5em;
        margin-left: -50%;
      }
      
    &:after {
        left: 0.5em;
        margin-right: -50%;
      }
  }

  .boxContent
  {
    background: $white;
    min-height: 50vh;
    border-left: solid 1px $lilywhite;
  }

.tabVertical
  {
    text-align: left;
    .MuiTabs-indicator
    {
        display: none !important;
    }
    button
    {
        text-align: left !important;
        text-transform: none;
        font-size: 0.938rem;
        justify-content: start !important;
        font-weight: 400 !important;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0.625rem 0.313rem;
        min-height: auto;
        color: $black-pearl;
        
        &.Mui-selected
        {
            font-weight: 600 !important;
            color: $black-pearl !important;
            border: 0px !important;
            box-shadow: none !important;
        }
    }

    &.profileTeacher
    {
        .MuiTabs-indicator
        {
            display: block !important;
            background: $primary;
        }
        button
        {
            padding: 10px 15px;
            &.Mui-selected
            {
                font-weight: 600 !important;
                color: $primary !important;
                border: 0px !important;
                box-shadow: none !important;
            }
        }
        
    }
  }
  
.bgBread
{
    background: linear-gradient(90deg, rgba(3,120,248,0.9) 0%, rgba(255,255,255,1) 100%);

    a, li
    {
        color: $white !important;
    }
}

.subtextBage
{
    font-size: 12px !important;
    line-height: 1;
}

.changePasswordTmp
{
    .btn
    {
        width: 100% !important;
    }
}

.testimonials-home
{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    .shadow-element
    {
            
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05) !important;   
    }

    .message
    {
        width:100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        line-height: 1.5;
        -webkit-box-orient: vertical;
        background:#fff;
        vertical-align: middle;
    }
    a
    {
        text-decoration: none;
        .name
        {
            font-size: 14px;
            text-decoration: none;
            color: $black-pearl;
        }
    }

    .data
    {
        text-transform: uppercase;
        font-size: 12px;
        color: $boulder;
    }

    .teacher
    {
        a
        {
            display: inline-block;
            border-bottom: $black-pearl 1px dotted;
            font-size: 14px;
            white-space: nowrap;
            color: $black-pearl;
            text-decoration: none;
            transition: all ease 0.5s;
            &:hover
            {
                border-bottom: $scieneblue 1px dotted !important;
                color: $scieneblue;
            }
        }
    }
    
    
    
}



.slider-design
{
    display: flex !important;
    flex-direction: row;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    .icon-slider
    {
        background: #fff;
        box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
        font-size: 40px !important;
        position: absolute;
        z-index: 10 !important;
        &:hover
        {
            color: $scieneblue;
            opacity: 1;
        }
    }
}

.topTeacherHome
{
    .btn
    {
        color: $boulder;
        border: 2px solid rgba(119, 119, 119, 0.05);
        border-radius: 5px;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        text-align: center;
        justify-content:center;
        box-shadow: none;
        cursor: pointer;
        transition: all ease 0.5s;
        &:hover
        {
            color:$scieneblue !important;
            border: 2px solid rgba(0, 114, 239, 1) !important;
        }
    }

    .card
    {
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
        border-radius: 5px;
        &:hover
        {
            box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.25);
        }
        .badgeImg
        {
            width: 70px;
            height: 70px;
        }
        .name
        {
            font-size: 16px;
            color: $black-pearl;
        }

        .proffesion
        {
            color:$boulder;
            font-size: 12px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .number
        {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            align-content: center;
            align-items: center;
            b
            {
                padding-left: 2px;
            }
        }

        .btn
        {
            border:  solid 2px $primary;
            color: $primary;
            text-transform: uppercase;
            padding: 7px 10px !important;
            font-size: 14px;
            transition: all ease 0.5s;
            box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
            &:hover
            {
                background: $primary;
                color: $white !important;
            }

        }

    }
}

.bannerSearch
{
    
    padding-top: calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320)));
    padding-bottom: calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320)));
    color: $white;
    background-size: cover;
    background-position: center center;
    @media only screen and (max-width: 768px)
    {
        background-position: center right;
    }
    .name
    {
        font-size: calc(34px + (48 - 34)*((100vw - 320px)/(2560 - 320)));
        font-weight: 700;
    }
    .description
    {
        font-size: calc(16px + (20 - 16)*((100vw - 320px)/(2560 - 320)));
        font-weight: 300;
        line-height: 1.5;
    }

    .searchForm
    {
        display: flex;
        flex-direction: row;
        background: $white;
        border-radius: 50px;
        align-content: center;
        box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
        .input
        {
            border: 0px !important;
            box-shadow: none;
        }
    } 
}

.bennefits
{
    background: $white;
    box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
    a
    {
        color: $black-pearl !important;
        text-decoration: none !important;
    }
}

.newsletterBlock
{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding-top: calc(25px + (80 - 25)*((100vw - 320px)/(2560 - 320)));
    padding-bottom: calc(25px + (80 - 25)*((100vw - 320px)/(2560 - 320)));
    color: $white;
    .description
    {
        font-size:calc(15px + (18 - 15)*((100vw - 320px)/(2560 - 320))); 
    }
    .title
    {
        font-size:calc(24px + (34 - 24)*((100vw - 320px)/(2560 - 320)));
        font-weight: 700;
    }

    .form
    {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: rgba(255,255,255,0.2);
        border-radius: 5px;
        align-items: center;
        padding-left: 15px;

        label, input
        {
            width: 100% !important;
            color: $white !important;
            text-align: start;
        }

        .btn
        {
            background: $white !important;
            color: $scieneblue;
            box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1) !important;
            margin: 0px !important;
            padding: 16px 35px !important;
            height: 3.75em !important;
            cursor: pointer;
            white-space: nowrap;
        }

        .input
        {
            width: 100%;
        }
    }
}

.section2Banner
{
    .image img
    {
        width: 100% !important;
        height: auto !important;
        border-radius: 10px;
    }

    .description
    {
        color: $boulder;
        font-size:calc(16px + (20 - 16)*((100vw - 320px)/(2560 - 320)));
    }

    .btn
    {
        background: $primary;
        box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
        border-radius: 5px;
        color: $white;
        text-transform: uppercase;
        padding: 15px 20px !important;
        text-decoration: none;
        transition: all ease 0.5s;
        display: inline-block;
        &:hover
        {
            text-decoration: none;
            box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.5);
            background: $scieneblue;
        }

    }
}

.recomandBanner
{
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(25px + (80 - 25)*((100vw - 320px)/(2560 - 320)));
    padding-bottom: calc(25px + (80 - 25)*((100vw - 320px)/(2560 - 320)));
    font-size:calc(16px + (18 - 16)*((100vw - 320px)/(2560 - 320)));
    background-size: cover;
    background-position: center center;
    .btn
    {
        background: $white;
        box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
        border-radius: 5px;
        color: $primary;
        text-transform: uppercase;
        padding: 15px 40px !important;
        text-decoration: none;
        transition: all ease 0.5s;
        display: inline-block;
        font-weight: 500;
        cursor: pointer;
        &:hover
        {
            text-decoration: none;
            box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.5);
            background: $background;
        }

    }
    
    .description
    {
        font-size:calc(15px + (18 - 15)*((100vw - 320px)/(2560 - 320))); 
    }
    .title
    {
        font-size:calc(24px + (34 - 24)*((100vw - 320px)/(2560 - 320)));
        font-weight: 700;
    }
}

.stars
{
    font-size: 12px !important;
    color: $boulder !important;
    align-items: flex-end;
}

.topCategoryHomeCours
{
    a
    {
        text-decoration: none !important;
    }
    .card
    {
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
        transition: all ease 0.5s;
        text-decoration: none !important;
        &:hover
        {
            box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.25);
            .name
            {
                color: $primary;
            }
        }
        img
        {
            width: 100% !important;
            height: 100%;
        }
        .name
        {
            text-decoration: none !important;
            text-align: center;
        }
    }
}

.sugestionItem
{
    background: $primary;
    box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
    text-align: center;
    border-radius: 5px;
    color: $white;
    padding: calc(15px + (50 - 15)*((100vw - 320px)/(2560 - 320)));
    padding-top: 25px;
    padding-bottom: 25px;
    box-sizing: border-box;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
    height: 100%;
    width: 100% !important;
    .description
    {font-size: 16px;}
}

.MuiGrid-item
{
    width: 100% !important;
}

.courseCardV
{
    box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05) !important;
    .imageZone
    {
        height: 180px;
        background: $lilywhite;
    }
    .icon-teacher
    {
        svg
        {
            width: 14px;
            height: 14px;
        }
    }
    
    .name
    {
        font-weight: 700 !important;
        font-size: 16px !important;
        color: $black-pearl;
        text-decoration: none;
        margin-bottom: 10px;
        line-height: 1.25;
        transition: all ease 0.5s;
        height: 40px;
        overflow: hidden;
        &:hover
        {
            color:$primary
        }
    }

    .stars
    {
        svg
        {
            width: 16px;
            height: 16px;
        }
    }

    .teacherName p
    {
        font-size: 12px !important;
        color: $boulder;
    }

    hr.MuiDivider-root.MuiDivider-vertical
    {
        background-color: #c4c4c4 !important;
        border-color:  #c4c4c4 !important;
        height: 15px !important;
    }

    .priceTotal
    {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.2;
        text-align: right;
        @media only screen and (max-width: 768px)
        {
            font-size: 16px;
        }
        span
        {
            padding-right: 5px;
        }
    }

    .pricePer
    {
        color: $success;
        font-size: 12px;
        line-height: 1.2;
        text-align: right;
        white-space: nowrap;
        @media only screen and (max-width: 768px)
        {
            font-size: 10px;
        }
        span
        {
            padding-right: 5px;
        }
    }

    .btn
    {
        font-size: 14px !important;
        white-space: nowrap;
        box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
        @media only screen and (max-width: 768px)
        {
            font-size: 12px;
        }
    }

    .detailsGeneral
    {
        .lp, .rp
        {
            font-size: 12px !important;
        }

        .lp
        {
            color:$boulder
        }
        
    }
}

.tabCourse
{
    border-bottom: solid 1px #F6FAFC;
    .name
    {
        font-size: 30px;
        font-size: 18px;
        font-weight: 600;
        text-transform: none;
        @media only screen and (max-width: 768px)
        {
            font-size: 14px;
        }
    }
}

.categoryTopBar
{
    border-bottom: solid 1px $lilywhite;
    z-index: 0;
    .name
    {
        white-space: nowrap;
    }
}

.populaCategories
{
    a
    {
        text-decoration: none !important;   
    }

    .name
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;

    }
    .card
    {
        color: $boulder;
            border: solid 2px $lilywhite;
            box-shadow: none;
            text-align: center;
            text-decoration: none !important;
            background: $white !important;
            transition: all ease 0.5s;
            text-decoration: none;
            border-radius: 5px;
            box-sizing: border-box;
            &:hover
            {
                color: $primary !important;
                border: solid 2px $primary;
                box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
            }
    }
}

.courseLIST
{
    .banner
    {
        color: $white;
        padding-top:calc(15px + (25 - 15)*((100vw - 320px)/(2560 - 320)));
        padding-bottom:calc(15px + (25 - 15)*((100vw - 320px)/(2560 - 320)));
    }
    .alert
    {
        background: $lilywhite;
        color: $black-pearl;
        font-size: 16px;
        display: flex;
        align-items: center;
        svg
        {
            color: $primary;
            width: 30px;
            height: 30px;
        }
    }
}

.filterLeft
{
    .accordion
    {
        box-shadow: none !important;
        border-radius: none !important;
        border-bottom: solid 1px #eee;
        background: $background;
        .summary
        {
            border-radius: none !important;
        }
    }

    .btn
    {
        display: inline-flex;
        align-items: center;
        align-content: center;
        color: $white;
        background: $primary;
        border-radius: 5px;
        flex-wrap: nowrap;
        text-transform: uppercase;
        font-size: 14px !important;
    }

}

.checkTerm .MuiTypography-root
{
    font-size: 14px !important;
    line-height: 1.1;
}

.detailCourse
{
    margin-top: 17px;
    .section1
    {
        background:#002146;
        color:$white;
        .btnWish
        {
            color: $white !important;
            border: solid 2px $white !important;
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            border-radius: 5px;
            padding: 10px 15px;
            transition: all ease 0.5s;
            text-transform: uppercase;
            &:hover
            {
                background: $primary;
                border: solid 2px $primary;
            }
        }

        .shareBtn
        {
            color: $white;
            border: solid 2px $white;
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            border-radius: 5px;
            padding: 10px 15px;
            transition: all ease 0.5s;
            &:hover
            {
                background: $primary;
                border: solid 2px $primary;
            }
        }
        .rightPart
        {
            position:absolute; 
            right:0; 
            top:0;
            @media only screen and (max-width: 768px)
            {
                position: static;
            }
        }

        .breadcrumbs
        {
            color: $boulder;
            a
            {
                color: $lilywhite;
                text-decoration: underline;
                font-weight: 300;
                transition: all ease 0.5s;
                &:hover
                {
                    color: $primary;
                }
            }
        }

        .shortDescription
        {
            font-size:calc(14px + (18 - 14)*((100vw - 320px)/(2560 - 320)));
            color: $lilywhite;
        }

        .title
        {
            font-size:calc(20px + (34 - 20)*((100vw - 320px)/(2560 - 320)));
            font-weight: 600;
        }

        .created
        {
            font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320))) !important;
            hr
            {
                height: 20px;
                border-color: $boulder !important;
            }
            .avatar
            {
                width: calc(30px + (60 - 30)*((100vw - 320px)/(2560 - 320)));
                height: calc(30px + (60 - 30)*((100vw - 320px)/(2560 - 320)));;
            }
        }
        .characteristic
        {
            box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
        
            .pricePer
            {
                color: $success;
                font-size: 14px;
                line-height: 1.2;
                text-align: right;
                white-space: nowrap;
                font-weight: 500;
                @media only screen and (max-width: 768px)
                {
                    font-size: 10px;
                }
                span
                {
                    padding:0 5px;
                }
            }

            .alert
            {
                text-align: center;
                color: $boulder;

            }
            .btn
            {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: 10px;
                transition: all ease 0.5s;
            }

            .btnSub
            {
                background: $primary;
                color: $white;
                &:hover
                {
                    background: $scieneblue;
                }
            }

            .btnSel
            {
                border: solid 2px $primary;
                color: $primary;
                &:hover
                {
                    border: solid 2px $scieneblue;
                    color: $scieneblue;
                }
            }

            

            .categories
            {
                color: $boulder;
                font-size: 14px;
                .nameFilter
                {
                    font-weight: 500;
                    color: $black-pearl;
                    text-align: right;
                }
            }
        }
    }
    .courseContent
    {
        box-shadow: none;
        border: solid 2px $lilywhite;

        .MuiSvgIcon-root
        {
            color: $black-pearl;
            font-size: 24px;
        }

        .MuiAccordionSummary-root.Mui-expanded
        {
            background: $lilywhite;
        }
        .name
        {
            font-weight: 600;
            font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)))
        }

        .time
        {
            color: $boulder;
            font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)));
            display: flex;
            align-items: center;
            .MuiSvgIcon-root
            {
                color: $boulder;
                font-size: 20px;
            }
        }

    }

    .courseVideoInfo
    {
        font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)));
        color: $boulder;
        text-align: center;
    }

    .detailsGeneral, .detailsGeneralround 
    {
        ul
        {

            padding: 0 0 0 18px;
        }
    }

    .courseGeneralData
    {
        p
        {
            font-size: 16px;
            font-weight: 500;
            font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)))
        }
        span
        {
            color: $boulder;
            font-weight: 400 !important;
        }
    }

    .loadMorebtn
    {
        color: $boulder;
        text-transform: uppercase;
        display: flex;
        width: 100%;
        padding: 15px;
        text-align: center;
        border-radius: 5px;
        border: solid 2px #eee;
    }

    .teacherDetailsDown
    {
        .avatar
        {
            width:calc(40px + (110 - 40)*((100vw - 320px)/(2560 - 320)));
            height: calc(40px + (110 - 40)*((100vw - 320px)/(2560 - 320)));
        }

        .info
        {
            .discipline
            {
                font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)));
            }

            .socialIcons
            {
                .MuiBox-root
                {
                    margin-left: 0px;
                    margin-right: 5px;
                }
                img
                {
                    width: 24px;
                    height: 24px;
                }
            }

            
        }

        .numbers
        {
            color: $boulder;
            h4
            {
                color: $black-pearl;
            }
        }
    }
}

.courseCardH
{
    box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05) !important;
    position: relative;
    
    @media only screen and (max-width: 768px)
    {
        flex-direction: column;
        padding-bottom: 65px;
        .buttons
        {
            position: absolute;
            bottom: 0;
            padding: 0 15px 15px 15px;
        }
    }
    .content
    {
        display: flex;
        flex-direction: row;
    }
    .imageZone
    {
        height: 1000%;
        background: $lilywhite;
        width: 310px !important;
    }
    .icon-teacher
    {
        svg
        {
            width: 14px;
            height: 14px;
        }
    }
    
    .name
    {
        font-weight: 700 !important;
        font-size: 16px !important;
        color: $black-pearl;
        text-decoration: none;
        margin-bottom: 10px;
        line-height: 1.25;
        transition: all ease 0.5s;
        overflow: hidden;
        margin-top: 15px;
        &:hover
        {
            color:$primary
        }

        @media only screen and (max-width: 768px)
        {
            padding: 0 15px;
            margin: 0px;
        }
    }

    .processteacher
    {
        @media only screen and (max-width: 768px)
        {
            padding: 0 15px;
            margin-top: 15px;
        }
    }

    .stars
    {
        svg
        {
            width: 16px;
            height: 16px;
        }
    }

    .teacherName p
    {
        font-size: 12px !important;
        color: $boulder;
    }

    hr.MuiDivider-root.MuiDivider-vertical
    {
        background-color: #c4c4c4 !important;
        border-color:  #c4c4c4 !important;
        height: 15px !important;
    }

    .priceTotal
    {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.2;
        text-align: right;
        @media only screen and (max-width: 768px)
        {
            font-size: 16px;
        }
        span
        {
            padding-right: 5px;
        }
    }

    .pricePer
    {
        color: $success;
        font-size: 12px;
        line-height: 1.2;
        text-align: right;
        white-space: nowrap;
        @media only screen and (max-width: 768px)
        {
            font-size: 10px;
        }
        span
        {
            padding-right: 5px;
        }
    }

    .btn
    {
        font-size: 14px !important;
        white-space: nowrap;
        background: none;
        border: solid 2px $primary;
        color: $primary;
        box-shadow: 0px 10px 15px -10px rgba(5, 19, 36, 0.15);
        &:hover
        {
            background: $primary;
            color: $white;
        }
        @media only screen and (max-width: 768px)
        {
            font-size: 12px;
        }
    }

    .detailsGeneral
    {
        .lp, .rp
        {
            font-size: 12px !important;
        }

        .lp
        {
            color:$boulder
        }

        
        
    }
    
    .dtP
    {
        margin-top: 15px;
        padding-right: 5px !important;
        @media only screen and (max-width: 768px)
        {
            margin: 0 15px !important;
        }
    }
}

.courseDetailPage
{
    box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
}

.menuGeneral
{
    display: flex;
    flex-direction: row;
    margin: auto;

    .icon-plus::after, .icon-minus::after
    {
        width: 12px;
        height: 12px;
        content: " ";
        display: inline-block;
        margin: 0 5px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .item
    {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 0 10px;
        a
        {
            color: $black-pearl;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
        }
    }
    .section1
    {
        position: absolute;
        z-index: 100;
        max-width: 1506px;
        left: 0px;
        right: 0px;
        margin: auto;
        margin-top: 20px;
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
        .icon-plus::after, .icon-minus::after
        {
            margin-top: 10px;
        }
        .content
        {
            background: $white;
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            margin-top: 36px;
            box-shadow: inset 0px 5px 20px -5px rgba(2, 11, 23, 0.1);
        }
        
        .item
        {
            margin: 0px;
        }
        a
        {
            display: block;
            width:400px;
            padding: 10px;
            font-size: 14px;
            font-weight: 400;
            transition: all ease 0.5s;
            display: flex;
            align-items: center;
            
            object
            {
                margin-right: 10px;
                width: 22px;
                height: 22px;
            }

            &:hover
            {
                object
                {
                    filter: invert(23%) sepia(98%) saturate(3039%) hue-rotate(201deg) brightness(96%) contrast(101%);
                }
                color: $primary;
            }
        }
    }

    .section2
    {
        margin: 0px;
        padding: 0px !important;
        display: inline-block;
        position: static !important;
        top: 33px !important;
        position: absolute !important;
        margin-top: 0px;
        left: 450px;
        height: 100%;
        background: none;
        .content
        {
            margin: 0px;
            box-shadow: none;
            background: none;
        }
        a
        {
        }
    }
}

.pageGeneral
{
    font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)));
    line-height: 1.5;
    .image
    {
        img
        {
            width: 100%;
            height: 300px;
        }
    }

    .onlyBread
    {
        background: linear-gradient(90deg, rgba(0, 114, 239, 0.72) 17.3%, rgba(255, 255, 255, 0.8) 100%), #FAFEFF;
        color: $white;
        p
        {
            color: $white;
        }
        a
        {
            color: $white;
            text-decoration: underline;
        }

        
    }
}

.shareIcon
{
    text-align: center;
    color: $boulder;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .fab::before
    {
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-bottom: 7px;
        background-size: contain;
        position: center center;
    }
}

.blogList
{
    a
    {
        text-decoration: none;
    }

    

    .blogCard
    {
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05) !important;
        height: 100% !important;

        .imageZone
        {
            height: 180px;
        }
        &:hover
        {box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.25) !important;}
        a
        {
            text-decoration: none;
            color: $primary;
        }

        .name
        {
            font-size: 16px;
            display: block;
            font-weight: 600;
            height: 48px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        .shortDescription
        {
            width:100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            line-height: 1.5;
            -webkit-box-orient: vertical;
            background:#fff;
            vertical-align: middle;
            font-size: 14px;
        }
    }
}

.blogCategory
{
    .item
    {

        font-size: 14px;
        cursor: pointer;
        margin: 7px 0;
        line-height: 1;
        padding: 20px;
        &:hover
        {
            text-decoration: underline;
        }
        .section1
        {
            .item
            {
                font-size: 12px !important;
                color: $primary;
            }
        }
    }
}

.blogDetail
{
    font-size:calc(14px + (16 - 14)*((100vw - 320px)/(2560 - 320)));
    .image img
    {
        width: 300px;
        height: 200px;
        margin-top: 10px;
    }
    a
    {
        color: $primary;
    }

    .blogSize
    {
        max-width: 810px;
    }
}

.pageWish
{
    .btnDelete
    {
        color: $danger;
        font-size: 14px !important;
        border: solid 2px $danger;
        &:hover
        {
            color: $white;
            background: $danger;
        }
    }

    .loadMorebtn
    {
        color: $boulder;
        border: 2px solid rgba(119, 119, 119, 0.05);
        border-radius: 5px;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        text-align: center;
        justify-content:center;
        box-shadow: none;
        cursor: pointer;
        transition: all ease 0.5s;
        font-size: 14px;
        width: 100%;
        &:hover
        {
            color:$scieneblue !important;
            border: 2px solid rgba(0, 114, 239, 1) !important;
        }
    }
}

.teacherProfile
{
    .loadMorebtn
    {
        color: $boulder;
        border: 2px solid rgba(119, 119, 119, 0.05);
        border-radius: 5px;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        text-align: center;
        justify-content:center;
        box-shadow: none;
        cursor: pointer;
        transition: all ease 0.5s;
        font-size: 14px;
        width: 100%;
        &:hover
        {
            color:$scieneblue !important;
            border: 2px solid rgba(0, 114, 239, 1) !important;
        }
    }

    .aboutTeacher
    {
        .avatar
        {
            width: calc(50px + (110 - 50)*((100vw - 320px)/(2560 - 320)));
            height: calc(50px + (110 - 50)*((100vw - 320px)/(2560 - 320)));
        }

        .numbers
        {
            color: $boulder;
            font-size: 14px;
            white-space: nowrap;
            h4
            {
                font-weight: 600;
                color: $black-pearl;
            }
        }

        .discipline
        {
            font-size: 16px;
        }

        .linear
        {
            color: $boulder !important;
        }
    }

    .ratingNumberSize
    {
        font-size: 64px !important;
        font-weight: 700;
        line-height: 1;
    }

    .ratingName
    {
        font-size: 12px;
        color: $boulder;
    }
}

.studentProfile
{
    .loadMorebtn
    {
        color: $boulder;
        border: 2px solid rgba(119, 119, 119, 0.05);
        border-radius: 5px;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        text-align: center;
        justify-content:center;
        box-shadow: none;
        cursor: pointer;
        transition: all ease 0.5s;
        font-size: 14px;
        width: 100%;
        &:hover
        {
            color:$scieneblue !important;
            border: 2px solid rgba(0, 114, 239, 1) !important;
        }
    }

    .aboutStudent
    {
        .avatar
        {
            width: calc(50px + (110 - 50)*((100vw - 320px)/(2560 - 320)));
            height: calc(50px + (110 - 50)*((100vw - 320px)/(2560 - 320)));
        }
        .socials img
        {
            width: 24px;
            height: 24px;
        }

        .discipline
        {
            font-size: 16px;
        }

    }

}
.socialName
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .blockEl
    {
        flex-basis: 50%;
        padding: 5px;
        box-sizing: border-box;
    }
    a
    {
        text-decoration: none;
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
        border-radius: 5px;
        padding: 15px 20px;
        box-sizing: border-box;
        border: 2px solid $lilywhite;
        background: $white;
        color:$boulder;
        text-transform: uppercase;
        font-size: 13px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
        transition: all ease 0.5s;
        &:hover
        {
            border: solid 2px $primary;
        }
        
        img
        {
            width: 20px;
            height: 20px;
            margin-right: 3px;
        }
    }
}

.becomeTeacher
{

    .subText
    {
        color: $boulder;
        font-size: calc(15px + (18 - 15)*((100vw - 320px)/(2560 - 320))) !important;;
    }
    .sectionFirst
    {
        
        padding-top: calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320)));
        padding-bottom: calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320)));
        color: $white;
        background-size: cover;
        background-position: center center;
        @media only screen and (max-width: 768px)
        {
            background-position: center right;
        }
        .name
        {
            font-size: calc(34px + (48 - 34)*((100vw - 320px)/(2560 - 320))) !important;
            font-weight: 700;
        }
        .description
        {
            font-size: calc(16px + (20 - 16)*((100vw - 320px)/(2560 - 320)));
            font-weight: 300;
            line-height: 1.5;
        }

        .btn
        {
            background: $white;
            border-radius: 5px;
            color: $primary;
            text-decoration: none;
            padding: 15px 20px !important;
            font-weight: 600;
            transition: all ease 0.5s;
            text-transform: uppercase;
            &:hover
            {
                background: $primary;
                color: $white;
            }
        }
    }

    
    .SectionSecond
    {
        .sugestionItem
        {
            background: $white !important;
            text-align: center;
            box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
            border-radius: 5px;
            box-sizing: border-box;
            padding: calc(15px + (30 - 15)*((100vw - 320px)/(2560 - 320))) ;
            p
            {
                font-size: 16px;
                color: $boulder;
            }
            a
            {
                color: $black-pearl;
                text-decoration: none;
            }
        }
    }

    .sectionFour
    {
        .item
        {
        text-align: center;
        color: $white;
        background-size: cover;
        background-position: center center;
        padding-top: calc(16px + (100 - 16)*((100vw - 320px)/(2560 - 320)));
        padding-bottom: calc(16px + (100 - 16)*((100vw - 320px)/(2560 - 320)));;
        .container
        {
            max-width: 860px;
        }
        p
        {
            font-size: calc(14px + (22 - 14)*((100vw - 320px)/(2560 - 320))) !important;
        }
        }
    }

    .sectionFive
    {
        color: $white;
        background-size: cover;
        background-position: center center;
        p
        {
            font-size: calc(14px + (18 - 14)*((100vw - 320px)/(2560 - 320)));
        }
        .btn
        {
            background: $white;
            border-radius: 5px;
            color: $primary;
            text-decoration: none;
            padding: 15px 40px !important;
            font-weight: 600;
            transition: all ease 0.5s;
            text-transform: uppercase;
            &:hover
            {
                background: $primary;
                color: $white;
            }
        }
    }

    .sectionThree
    {
        color: $white !important;
        text-align: center;
        .bennefitsItem
        {
            text-align: center;
            a
            {
                color: $white !important;
                text-align: center;
                text-decoration: none;
                background: #002146;
                h5
                {
                    text-transform: uppercase;
                    font-size: 28px;
                    margin-bottom: 15px;
                }
            }
        }
        
    }
}

.becomeSteps
{
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal
    {
        border: 0px !important;
    }

    .Mui-active
    {
        .stepText
        {
            color: $primary;
        }

        .stepp
        {
        filter: grayscale(100%) brightness(200%) hue-rotate(360deg) saturate(1000%) contrast(1000%);
        }
    }
    .stepText
    {
        display: block;
        text-align: center;
        font-size: calc(10px + (18 - 10)*((100vw - 320px)/(2560 - 320)));
        color: $boulder;
        margin-top: 15px !important;
    }
    
    .step
    {
        .MuiStepLabel-root
        {
            display: flex;
            flex-direction: column;
            max-width: 250px;
        }
        .MuiStepLabel-iconContainer
        {
            width: calc(40px + (90 - 40)*((100vw - 320px)/(2560 - 320)));
            height: calc(40px + (90 - 40)*((100vw - 320px)/(2560 - 320)));
            box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
            background: $white;
            border-radius: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px !important;
            padding: 0px !important;
        }

        .Mui-active.MuiStepLabel-iconContainer
        {
            background: $primary;

        }
    }
    
}

.detailsContentBecome
    {
        display: flex;
        align-content: center;
        justify-content: center;
        max-width: 1200px;
        left: 0px;
        right: 0px;
        margin: auto;

        .image img
        {
            width: 420px !important;
            height: 250px !important;
        }

        @media only screen and (max-width: 768px)
        {
            .image img
            {
                width: 100% !important;
                height: 100%!important;
            }
        }
    }


.mobileMenu
{
    .logo
    {
        margin: 15px !important;
    }

    ul, li
    {
        padding: 0px !important;
        margin: 0px !important;
    }

    .toBeTeacher
    {
        background: $lilywhite;
        border-radius: 50px;
        color: $primary;
        margin: 0 15px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        text-align: center;
        width: 100%;
        justify-content: center;
        font-weight: 600;
    }

    .btn
    {
        text-align: center !important;
        padding: 10px;
        box-sizing: border-box;
        margin: 5px 15px;
        border-radius: 5px;
        text-transform: uppercase;
        justify-content: center;
    }

    .btnBorder
    {
        border: solid 2px $primary;
        color: $primary;
    }

    .btnContainer
    {
        background: $primary;
        color: $white;
    }
    .menuMobile

    {
        a
        {
            color: $black-pearl;
            margin: 10px;
        }
        
        
    }

    
    
    
}

.iconRight
    {
        width: 15px !important;
        height: 15px !important;
        color: $black-pearl;
    }

    .nameTopC
    {
        color: $primary;
        justify-content: flex-start;
        font-weight: 600 !important;
        text-align: left;
        font-size: 14px !important;
    }

.linkSecond
{
    font-size: 14px;
    color: $black-pearl !important;
    .iconCate
    {
        min-width: 40px;
    }

}

.withoutCourse
{
    .cardSpecial
    {
        background: $white;
        border-radius: 10px;
        box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
        padding: calc(15px + (40 - 15)*((100vw - 320px)/(2560 - 320)));
        box-sizing: border-box;
        
    }

    .btn
        {
            background: $primary;
            color: $white;
            border-radius: 5px;
            padding-left: 15px !important;
            padding-right: 15px !important;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            transition: all ease 0.5s;
            text-transform: uppercase;
            &:hover
            {
                background: $scieneblue;
            }
            &.out
            {
                background: none;
                border: solid 2px $primary;
                color: $primary;
                display: inline-block;
                font-size: 14px;
            }
        }

    .preText
    {
        font-size: calc(18px + (28 - 18)*((100vw - 320px)/(2560 - 320))) !important;
        text-align: center;
    }

    .listBanner  
    {
        img
        {
            width: 100%;
            height: 100%;
        }
        .item .cardSpecial
        {
            align-items: stretch;
            display: flex;
            height: 100% !important;
        }
        .item:nth-child(2), .item:nth-child(3)
        {
            width: 50% !important;
            display: inline-block;
            @media only screen and (max-width: 768px)
            {
                width: 100% !important;
            }
        }
    }
    
}

.teacherDrawer
{
    .hD
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 20px;
    }
    .MuiDrawer-paper
    {
        background: $black-pearl;
    }

    .itemOne
    {
        color: $white;
        &:hover
        {
            background: $scieneblue;
        }
    }
    
    .Mui-selected
    {
        background-color: $primary !important;
        color: $black-pearl !important;
        
        .itemOne
        {
            color: $black-pearl !important;
        }
        & ::after
        {
            color: $white;
            
        }
    }

    
}  

.teacherDrawerSecond
{
    .processHeader
    {
        color: $primary !important;
            text-align: center !important;
            font-weight: 700 !important;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            .name
            {
                font-weight: 700 !important;
                width: 100%;
                color: $primary !important;
            }
    }

    a
    {
        color: $boulder;
        .MuiListItemIcon-root
        {
            display: none;
        }
    }
}

.likeCard
{
    padding: 15px;
    background: $white;
    box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
    border-radius: 5px;
}

.btn-add
{
    background: $primary;
    align-items: center;
    padding-left: 15px !important;
    border-radius: 5px;
    color: $white !important;
    text-decoration: none !important;
    display: inline-flex;
}

.ck-editor
{
    --ck-color-base-border: hsl(0, 0%, 100%);
    box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.05);
    border-radius: 5px !important;
    

    
    .ck-content
    {
        background: $white !important;
        min-height: 300px !important;
        border-bottom: solid 2px $boulder !important;
        border-radius: 5px !important;
        &.ck-focused
        {
            border: 0px !important;
            border-bottom: solid 2px $primary !important;
        }
    }

    .ck-toolbar
    {
        border: 0px !important;
        box-shadow: none !important;
    }
}

.courseGallery
{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: dashed 2px #ddd;
    border-radius: 5px;
    img
    {
        border-radius: 5px;
    }
}


.dialogAdd
{
    .MuiPaper-root
    {
        background: $background;
    }
    
}

.btnAddlesson
{
    background: $primary !important;
    color: $white !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.accordionItem
{
    box-shadow: 0px 5px 20px -5px rgba(2, 11, 23, 0.1) !important;
}

.back-link-element
{
    color: #051324 !important;
    text-decoration: none !important; 
    p
    {
        font-weight: 600 !important;
    }
    
}

.support-category
{
    text-align: center !important;
    &.Mui-selected
    {
        border-bottom: solid 3px $primary;
        background: none !important;
    }
    span
    {
        font-size: 26px !important;
        font-weight: 500 !important;
    }
}

.general-tab-support
{
    display: flex;
    justify-content: center;
    margin: auto;
    left: 0;
    right:0;
}

.item-faq-block
{
    border: solid 2px $lilywhite !important;
    border-radius: 5px !important;
    background: $white !important;
    font-weight: 600 !important;
    transition: all ease 0.5s !important;
    span
    {
        font-weight: 600 !important;
        font-size: 18px;
        @media only screen and (max-width: 768px)
        {
            font-size: 14px;
        }
    }
    &:hover
    {
        border: solid 2px $primary !important;
        color: $primary !important;
    }
}

.category
{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
    .item-category
    {
        text-align: center;
        display: flex;
        flex-direction: column;
        background: $white;
        padding: 15px;
        border: solid 2px $lilywhite;
        border-radius: 15px;
        transition: all ease 0.5s;
        &:hover
        {
            border: solid 2px $primary;
            box-shadow: 0px 5px 30px rgba(5, 19, 36, 0.05);
        }
    }
}

.title-support
{
    font-size: 26px !important;
    color: $boulder !important;
}

.w-icon
{
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
}

.content-support
{
    background: $white;
    min-height: 75vh;
    padding: 15px;
    box-sizing: border-box;
    border-left:solid 1px $lilywhite;
    font-size: 14px;
    line-height: 1.5;
}

.content-support-alt
{
    background: $white;
    min-height: 75vh;
    padding: 15px 15px 15px 0;
    box-sizing: border-box;
    border-right:solid 1px $lilywhite;
    font-size: 14px;
    line-height: 1.5;
}

.link-classic
{
    text-decoration: none !important;
    & :hover
    {
        text-decoration: underline;
    }
}

.searchForm-bread
    {
        display: flex;
        flex-direction: row;
        background: $white;
        border-radius: 50px;
        align-content: center;
        box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
        width: 100%;


        .MuiTextField-root
        {
            border: 0px !important;
            outline: none !important;
            width: 100% !important;
        }
        
        fieldset
        {
            border: 0px !important;
            outline: none !important;
        }

        .MuiInputLabel-shrink
        {
            display: none !important;
        }


        label
        {
            font-size: 14px;
            &.Mui-focused, .MuiInputLabel-animated
            {
                display: none !important;
            }
        }
        input
        {
            border: 0px !important;
            outline: none !important;
            box-shadow: none;
        }
    }

    .w-diferent
    {
        width: 450px;
        @media only screen and (max-width: 768px)
        {
            width: 100%;
            margin-top: 20px;
        }
    }

    .list-item
    {
        color: $primary;
        &:hover
        {
            text-decoration: underline;
        }
    }

    .supportarticleDetail
    {
        background: $white;
    }

.right-section
{
    background: $background;
    border-left:solid 1px $lilywhite;

}

.bg-left
{

    background: linear-gradient(90deg, rgba(250,254,255,1) 0%, rgba(250,254,255,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    @media only screen and (max-width: 768px)
    {
        background: #FFF;
    }
}


.bg-right
{

    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(250,254,255,1) 50%, rgba(250,254,255,1) 100%);
    @media only screen and (max-width: 768px)
    {
        background: #FFF;
    }
}

.bg-center
{
    background: linear-gradient(90deg, rgba(232,250,255,1) 0%, rgba(232,250,255,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    @media only screen and (max-width: 768px)
    {
        background: #FFF;
    }
}
    



